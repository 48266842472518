import { AuthManager as Auth0Manager } from "@mantu/erp-spa-auth0";
import EntraAuthManager from "@mantu/erp-spa-entra-id";

const ProviderType = {
  AUTH0: "auth0",
  ENTRAID: "entra-id",
};

const providerName = process.env.AUTH_PROVIDER || ProviderType.AUTH0;

const createAuthManager = () => {
  if (providerName === ProviderType.AUTH0) {
    const authConfig = {
      VITE_APP_AUTH_DOMAIN: process.env.AUTH0_DOMAIN || '',
      VITE_APP_AUTH_CLIENT_ID: process.env.AUTH0_CLIENT_ID || '',
    };

    if (!authConfig.VITE_APP_AUTH_DOMAIN || !authConfig.VITE_APP_AUTH_CLIENT_ID) {
      throw new Error("Auth0 configuration is missing required values.");
    }

    return new Auth0Manager(authConfig);
  }

  const entraIdConfig = {
    clientId: process.env.ENTRA_CLIENT_ID,
    authority: process.env.ENTRA_AUTHORITY,
    scopes: process.env.ENTRA_SCOPES,
  };

  return new EntraAuthManager(entraIdConfig);
};

const authManager = createAuthManager();

export const initializeAuth = async (registerSpaApps) => {
  try {
    await authManager.initializePortal(registerSpaApps);
  } catch (error) {
    console.error("Failed to initialize the authentication portal:", error);
    throw error;
  }
};

export const logout = async (returnTo) => {
  try {
    await authManager.logout(returnTo ? { returnTo } : undefined);
    console.log("User successfully logged out.");
  } catch (error) {
    console.error("Logout failed:", error);
    throw error;
  }
};

export const getToken = async () => {
  try {
    const response = await authManager.getToken();
    return providerName === ProviderType.AUTH0 ? response.token : response.accessToken;
  } catch (error) {
    console.error("Get token failed:", error);
    throw error;
  }
};

export const getEntraPhotoUrl = async () => {
  if(providerName === ProviderType.AUTH0) return undefined;
  const photoUrl = await authManager.getProfilePhoto({ scopes: ["User.Read"] });
  return photoUrl;
};

export { authManager };
